import { useTranslation } from "react-i18next";
import TitleLabel from "../../components/common/TitleLabel/TitleLabel";
import SubtitleLabel from "../../components/common/SubtitleLabel/SubtitleLabel";
import "./Register.styles.sass";
import RegisterForm from "./modules/RegisterForm";

const Register = () => {
    const [t]: any = useTranslation("global");
   
    return (
        <div className="loginFormContainer">
            <div className="formContainer">
                <TitleLabel label={t("register.title")} />
                <SubtitleLabel label={t("register.subtitle")} />

                <RegisterForm  />
            </div>
        </div>
    );
};

export default Register;
