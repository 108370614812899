import { Routes, Route, BrowserRouter, HashRouter } from "react-router-dom";
import PatientSurvey from "./pages/PatientSurveys/PatientSurvey";
import AuthFrame from "./frames/AuthFrame";
import Base from "./pages/Base";
import ConnectYourAccounts from "./pages/ConnectYourAccounts/ConnectYourAccounts";
import OTPValidation from "./pages/OTPValidation/OTPValidation";

function App() {
    return (
        <div className="cexAuthApp">
            <BrowserRouter>
                <Routes>
                    <Route element={<PatientSurvey />} path="/survey" />
                    <Route element={<Base />} path="/" />
                    <Route element={<ConnectYourAccounts />} path="/connectyouraccounts" />
                    <Route element={<OTPValidation />} path="/validation" />
                </Routes>
            </BrowserRouter>
            <HashRouter>
                <Routes>
                    <Route element={<AuthFrame login />} path="/login" />
                    <Route element={<AuthFrame register />} path="/register" />
                    <Route element={<AuthFrame forgotPassword />} path="/forgot" />
                    <Route element={<AuthFrame resetPassword />} path="/reset" />
                </Routes>
            </HashRouter>
        </div>
    );
}

export default App;
