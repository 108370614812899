import Login from "../pages/Login/Login";
import AuthBody from "../components/common/AuthBody/AuthBody";
import "../App.sass";
import carexLogo from "../assets/images/Careexpand-Logo-no-padding.png";
import Register from "../pages/Register/Register";
import ForgotPassword from "../pages/ForgotPassword/ForgotPassword";
import ResetPassword from "../pages/ResetPassword/ResetPassword";
import ConnectYourAccounts from "../pages/ConnectYourAccounts/ConnectYourAccounts";
import Base from "../pages/Base";

interface IAuthFrameProps {
    base?: boolean;
    connectyourAccounts?: boolean;
    login?: boolean;
    register?: boolean;
    forgotPassword?: boolean;
    resetPassword?: boolean;
}

const AuthFrame = (props: IAuthFrameProps) => {
    const {
        base,
        connectyourAccounts,
        login,
        register,
        forgotPassword,
        resetPassword
    } = props;
    return (
        <div className="loginBodyContainer">
            <div className="carexLogoContainer0">
                <img className="carexLogo" src={carexLogo} alt="Careexpand_logo" />
                <div className="carexLogoText">The Operating System for Virtual Medicine</div>
            </div>
            <div className="leftSideDiv">
                <AuthBody />
            </div>
            <div className="rightSideDiv">
                {base && <Base />}
                {connectyourAccounts && <ConnectYourAccounts />}
                {login && <Login />}
                {register && <Register />}
                {forgotPassword && <ForgotPassword />}
                {resetPassword && <ResetPassword />}
            </div>
        </div>
    );
}

export default AuthFrame;
